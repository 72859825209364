import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"

const SectionWrapper = styled.section`
  padding: 140px 0 80px 0;
  @media (max-width: 990px) {
    padding: 100px 0 60px 0;
  }
  @media (max-width: 767px) {
    padding: 100px 0 40px 0;
  }
`

export const PrivacyWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media screen and (max-width: 700px) {
    justify-content: space-between;
  }
`

export const PrivacyText = styled.div`
  color: ${themeGet("colors.black")};
  line-height: 24px;
  font-size: 20px;
  font-weight: 300;
  line-height: 30px;
  @media only screen and (max-width: 990px) {
    font-size: 18px;
  }
  @media only screen and (max-width: 480px) {
    font-size: 16px;
    line-height: 28px;
  }

  h1 {
    font-size: px;
    font-weight: 700;
    line-height: 40px;
    margin: 0px;
    @media only screen and (max-width: 990px) {
      font-size: 22px;
    }
    @media only screen and (max-width: 480px) {
      font-size: 18px;
      line-height: 28px;
    }
  }

  h2 {
    font-size: 24px;
    font-weight: 700;
    line-height: 40px;
    margin: 0px;
    @media only screen and (max-width: 990px) {
      font-size: 22px;
    }
    @media only screen and (max-width: 480px) {
      font-size: 18px;
      line-height: 28px;
    }
  }
`

export default SectionWrapper
