import React from "react"
import striptags from "striptags"

import Container from "../../components/UI/Container"
import SectionWrapper, {
  PrivacyWrapper,
  PrivacyText,
} from "./privacyPolicy.style"
import SectionHeading from "../../components/SectionHeading"

const PrivacyPage = ({ title, titleColored, content }) => {
  return (
    <SectionWrapper>
      <Container width="1220px">
        <SectionHeading
          heading={title.html.substring(1, 3)}
          firstPart={striptags(title.html)}
          secondPart={striptags(titleColored.html)}
        />
        <PrivacyWrapper>
          <PrivacyText>
            <div dangerouslySetInnerHTML={{ __html: content.html }} />
          </PrivacyText>
        </PrivacyWrapper>
      </Container>
    </SectionWrapper>
  )
}

export default PrivacyPage
