import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"

const HGroup = styled.hgroup`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 20px;
  @media (max-width: 740px) {
    flex-direction: column;
  }

  .heading-first {
    color: ${themeGet("colors.black")};
    font-size: 40px;
    margin-right: 18px;
    margin-bottom: 0px;
    @media (max-width: 990px) {
      font-size: 32px;
    }
    @media (max-width: 480px) {
      font-size: 26px;
    }
  }

  .heading-second {
    color: ${themeGet("colors.mainBlue")};
    font-size: 40px;
    line-height: 68px;
    @media (max-width: 990px) {
      font-size: 32px;
    }
    @media (max-width: 480px) {
      font-size: 26px;
      line-height: 28px;
    }
  }
`

export default HGroup
