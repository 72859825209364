import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../containers/Layout"
import PrivacyPolicy from "../containers/PrivacyPolicy"
import SEO from "../components/SEO"
import { ContentWrapper } from "../containers/alpacked.style"

const PrivacyPage = ({ location }) => {
  const data = useStaticQuery(graphql`
    query PrivacyPolicy {
      prismicPrivacyPolicy {
        data {
          title {
            html
          }
          title_colored {
            html
          }
          content {
            html
          }
          seo_title {
            text
          }
          seo_short_description {
            text
          }
          image_link_preview {
            fluid {
              src
            }
          }
          seo_description {
            text
          }
        }
      }
    }
  `)

  const {
    content,
    seo_description,
    seo_short_description,
    seo_title,
    image_link_preview,
    title,
    title_colored,
  } = data.prismicPrivacyPolicy.data

  return (
    <>
      <SEO
        image={image_link_preview.fluid?.src}
        location={location}
        title={seo_title.text}
        shortDesc={seo_short_description.text}
        description={seo_description.text}
      />
      <Layout pathname="/privacy-policy">
        <ContentWrapper>
          <PrivacyPolicy
            title={title}
            titleColored={title_colored}
            content={content}
          />
        </ContentWrapper>
      </Layout>
    </>
  )
}

export default PrivacyPage
