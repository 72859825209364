import React from "react"
import Heading from "../../components/Heading"
import HGroup from "./sectionHeading.style"

const SectionHeading = ({ firstPart, secondPart, heading, ...props }) => {
  return (
    <HGroup {...props}>
      <Heading as={heading} content={firstPart} className="heading-first" />
      <Heading as={heading} content={secondPart} className="heading-second" />
    </HGroup>
  )
}

export default SectionHeading
